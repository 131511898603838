import { Container, Row, Button, Tooltip } from "reactstrap";
import BreadCrumb from "../../Common/BreadCrumb";
import UiTabs from "../../../pages/BaseUi/UiTabs/UiTabs";
import { useToggle } from "../../../customHooks";
import CampaignCreateModal from "./CampaignCreateModal";
import { useOrganizationStore } from "../../../Store/store";
import { useState } from "react";
import { UilInfoCircle } from "@iconscout/react-unicons";
import { OrganizationStatus } from "../../constants/layout";

const CampaignPage = () => {
  document.title = "Campaigns | MarketBuzz";
  const [showCreateCampaign, toggleShowCreateCampaign] = useToggle(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [organization] = useOrganizationStore((state) => [state.organization]);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="SMS Campaigns" pageTitle="Campaigns" />
        <Row className="project-wrapper">
          <div className="d-flex justify-content-end mb-2">
            <Button
              color="primary"
              onClick={toggleShowCreateCampaign}
              className="me-4"
              disabled={organization.status !== OrganizationStatus.ACTIVE}
            >
              + Create New Campaign
            </Button>
            {organization.status !== OrganizationStatus.ACTIVE && (
              <>
                <span id="TooltipInfo">
                  <UilInfoCircle size={20} />
                </span>
                <Tooltip
                  placement="right"
                  isOpen={tooltipOpen}
                  target="TooltipInfo"
                  toggle={toggle}
                  className="custom-tooltip tooltip-inner"
                >
                  <p className="text-info text-justify">
                    We're syncing your data in the background. Campaign creation
                    will be available shortly.
                  </p>
                </Tooltip>
              </>
            )}
          </div>

          <UiTabs />
        </Row>
      </Container>
      {showCreateCampaign && (
        <CampaignCreateModal
          showModal={showCreateCampaign}
          closeModal={toggleShowCreateCampaign}
          fade={true}
        />
      )}
    </div>
  );
};

export default CampaignPage;
