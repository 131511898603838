import React, { useCallback, useState } from "react";
import { Row, Col, Card, CardBody, Button, Badge, Tooltip } from "reactstrap";
import UiCampaignEditModal from "../UiModals/UiCampaignEditModal";
import { getTemplates } from "../../../services";
import { useAuthStore } from "../../../Store/store";
import { TTemplateModelJSON } from "@shoutout-labs/market_buzz_crm_types";
import { PerCreditValue } from "@shoutout-labs/marketbuzz-constants";
import {
  CampaignName,
  CampaignType
} from "../../../Components/constants/campaign";
import Loader from "../../../Components/Common/Loader";
import { useOrganizationStore } from "../../../Store/store";
import { Utility } from "../../../utils";
import { useQuery } from "@tanstack/react-query";
import { UilInfoCircle } from "@iconscout/react-unicons";
import { OrganizationStatus } from "../../../Components/constants/layout";

const UiCards: React.FC<{ toggle: any; totalCustomers: number }> = ({
  toggle,
  totalCustomers
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [limit, setLimit] = useState<number>(10);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [skip, setSkip] = useState<number>(0);
  const [isLoggedIn] = useAuthStore((state) => [state.isLoggedIn]);
  const [tooltipStates, setTooltipStates] = useState<{
    [key: string]: boolean;
  }>({});
  const [organization] = useOrganizationStore((state) => [state.organization]);

  const [selectedTemplate, setSelectedTemplate] =
    useState<TTemplateModelJSON>(null);

  const { data: templates, isLoading } = useQuery({
    queryKey: ["templates", skip],
    queryFn: async () => {
      const queryObj = {
        limit,
        skip
      };
      const response = await getTemplates(queryObj);
      return response.items;
    },
    enabled: isLoggedIn,
    refetchOnWindowFocus: false
  });

  const selectTemplate = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      const id = e.currentTarget.dataset.id;
      if (id) {
        setSelectedTemplate(templates.find((item) => item.id === id));
      }
    },
    [setSelectedTemplate, templates]
  );

  const onCloseModal = useCallback(() => {
    setSelectedTemplate(null);
  }, [setSelectedTemplate]);

  const toggleTooltip = (templateId: string) => {
    setTooltipStates((prev) => ({
      ...prev,
      [templateId]: !prev[templateId]
    }));
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Row>
          {templates.length > 0 &&
            templates.map((template, index) => (
              <Col xl={14} key={index}>
                <Card>
                  <CardBody>
                    <div>
                      <h6 className="mt-0">
                        {template.type}
                        {template?.isNewTemplate && (
                          <Badge color="primary" className="mx-2">
                            New
                          </Badge>
                        )}
                      </h6>
                      <Row>
                        <Col xl={6} lg={6} md={6} sm={12}>
                          <p>Message Text</p>
                          <Row>
                            <p>
                              {Utility.replaceOrganizationCustomAttribute(
                                template.messageBody,
                                organization?.name
                              )}
                            </p>
                          </Row>
                        </Col>
                        <Col xl={2} lg={2} md={2} sm={4}>
                          <p>
                            No. Customers
                            <>
                              <span
                                id={`TooltipInfo-${template.id}`}
                                className="mx-2"
                              >
                                <UilInfoCircle size={15} />
                              </span>
                              <Tooltip
                                placement="right"
                                isOpen={tooltipStates[template.id] || false}
                                target={`TooltipInfo-${template.id}`}
                                toggle={() => toggleTooltip(template.id)}
                                className="custom-tooltip tooltip-inner"
                              >
                                <p className="text-info text-justify">
                                  Number of customers who have provided a mobile
                                  number and opted in for marketing.
                                </p>
                              </Tooltip>
                            </>
                          </p>
                          <Row>
                            <p>{totalCustomers}</p>
                          </Row>
                        </Col>
                        <Col xl={2} lg={2} md={2} sm={4}>
                          <p>Estimated Cost</p>
                          <Row>
                            <p>
                              {template?.costPerMessage * totalCustomers} Buzz
                              Credit/s
                            </p>
                            <p>
                              $
                              {(
                                template?.costPerMessage *
                                totalCustomers *
                                PerCreditValue
                              ).toFixed(3)}
                            </p>
                          </Row>
                        </Col>
                        <Col xl={2} lg={2} md={2} sm={4}>
                          <p></p>
                          <Row>
                            <p>
                              <Button
                                color="outline-primary"
                                onClick={selectTemplate}
                                data-id={template.id}
                                disabled={
                                  totalCustomers === 0 ||
                                  organization.status !==
                                    OrganizationStatus.ACTIVE
                                }
                              >
                                Setup Campaign
                              </Button>
                            </p>
                          </Row>
                        </Col>
                      </Row>
                      {templates.length === 0 && <p>No data found..</p>}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
        </Row>
      )}

      {!!selectedTemplate && (
        <UiCampaignEditModal
          showModal={!!selectedTemplate}
          closeModal={onCloseModal}
          fade={true}
          messageText={selectedTemplate?.messageBody}
          name={selectedTemplate?.type as unknown as CampaignName}
          toggleTab={toggle}
          costPerMessage={selectedTemplate?.costPerMessage}
          type={CampaignType.BROADCAST}
        />
      )}
    </>
  );
};

export default UiCards;
